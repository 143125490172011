<template>
    <div>
        <div class="row">
            <div class="col col-8">
            <div class="font-weight-bold">{{ item.target_name }}</div>
            </div>
            <div class="col col-4 p-0" v-if="canEdit">
                <div class="card-action-group">
                    <div class="card-action" @click="$emit('edit', {item, index})">
                        <i class="fas fa-edit"></i>
                    </div>
                    <div class="card-action" @click="$emit('delete', {item, index})">
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <span class="font-weight-bold mt-4">{{ $t('DECISION.CARD.PURPOSE_LABEL') }}:</span>
            <span class="ml-2">{{ item.purpose_name }}</span>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t('DECISION.CARD.DECISION_LABEL') }}:</span>
            <span class="ml-2">{{ item.decision_name }}</span>
        </div>
        <div v-if="item.extension_name">
            <span class="font-weight-bold">{{ $t('DECISION.CARD.EXTENSION_LABEL') }}:</span>
            <span class="ml-2">{{ item.extension_name }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ApplicationDecisionItemCard',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: false,
        }
    },
}
</script>