import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import { DecisionPurposeField } from '@/modules/decision-purpose/decision-purpose-field';
import { DecisionField } from '@/modules/administrator/fields/decision-field';

const field = (name) => i18n.t(`DECISION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DECISION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    purpose_id: DecisionPurposeField.relationToOne(
        'purpose_id',
        field('purpose_id'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    decision_id: DecisionField.relationToOne(
        'decision_id',
        field('decision_id'),
        { autocompleteRequired: true },
        {},
        [{
            key: 'parent_id',
            name: 'purpose_id',
        }],
        { asyncData: true }
    ),
};

export class ApplicationDecisionModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
